const Portfolio = _ => {
    return (
        <section id="portfolio">
            <header>
                <h2>PORTFOLIO</h2>
            </header>
            <div>
                <article>
                    <img src="/file/image/abstract/min/abstract4.jpg" alt="background"/>
                    <div>
                        <h3>E-commerce</h3>
                        <ul>
                            <li>Java</li>
                            <li>Spring</li>
                            <li>React</li>
                            <li>Bootstrap</li>
                            <li>Rest</li>
                        </ul>
                        <ul>
                            <li>account system</li>
                            <li>catalogue system</li>
                            <li>search system</li>
                            <li>score system</li>
                            <li>review system</li>
                            <li>payment system</li>
                        </ul>
                        <nav>
                            {/* <a href="https://gitlab.com/"><span><i className="fab fa-git"/></span></a> */}
                            {/* <a href="https://wilhelmvonhabsburg.com/project/e-commerce"><span><i className="fas fa-eye"/></span></a> */}
                        </nav>
                    </div>
                </article>
                <article>
                    <img src="/file/image/abstract/min/abstract5.jpg" alt="background"/>
                    <div>
                        <h3>File System</h3>
                        <ul>
                            <li>Rust</li>
                            <li>Bash</li>
                            <li>Rsync</li>
                        </ul>
                        <ul>
                            <li>file backup</li>
                            <li>file lookup</li>
                        </ul>
                        <nav>
                            {/* <a href="https://gitlab.com"><span><i className="fab fa-git"/></span></a> */}
                           {/*  <a href="https://wilhelmvonhabsburg.com/project/file-system"><span><i className="fas fa-eye"/></span></a> */}
                        </nav>
                    </div>
                </article>
                <article>
                    <img src="/file/image/abstract/min/abstract2.jpg" alt="background"/>
                    <div>
                        <h3>Musician Portfolio</h3>
                        <ul>
                            <li>Express.js</li>
                            <li>React.js</li>
                            <li>SaSS</li>
                        </ul>
                        <ul>
                            <li>contact form</li>
                        </ul>
                        <nav>
                            {/* <a href="https://gitlab.com"><span><i className="fab fa-git"/></span></a> */}
                            <a href="https://walterschnellbacher.com"><span><i className="fas fa-eye"/></span></a>
                        </nav>
                    </div>
                </article>
            </div>
        </section>
    )
}


export default Portfolio
