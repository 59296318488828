import { useState, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import { useSelector, useDispatch } from 'react-redux'

import { toggle__menu, hide } from 'store/action'


const screen = 790


const Header = _ => {
    const dispatch = useDispatch()
    const [ home, update_home ] = useState(window.innerWidth >= screen ? `WILHELM VON HABSBURG` : `WvH`)
    const menu__toggled = useSelector(({ toggler }) => toggler.menu__toggled)

    const click__hide = _ => dispatch(hide())
    
    useEffect(_ => {
        const resize = _ => {
            if (window.innerWidth >= screen) {
                update_home(`WILHELM VON HABSBURG`)
                dispatch(hide())
            } else {
                update_home(`WvH`)
            }
        }
        window.addEventListener(`resize`, resize)
        return _ => window.removeEventListener(`resize`, resize)
    }, [ dispatch ])
    
    return (
        <header id="header">
            <nav>
                <HashLink to="#main" smooth>{ home }</HashLink>
            </nav>
            <nav className={ menu__toggled ? `colored` : `` }>
                <HashLink to="#about" smooth>About</HashLink>
                <HashLink to="#portfolio" smooth>Portfolio</HashLink>
                <a href="/file/pdf/resume.pdf">Resume</a>
                {/* <HashLink to="#contact" smooth>Contact</HashLink> */}
                <button onClick={ _ => dispatch(toggle__menu()) }><i className={ menu__toggled ? `far fa-window-close` : `fas fa-bars` }/></button>
            </nav>
            <nav className={ menu__toggled ? `` : `collapsed` }>
                <HashLink onClick={ click__hide } to="#about" smooth>About</HashLink>
                <HashLink onClick={ click__hide }  to="#portfolio" smooth>Portfolio</HashLink>
                <a onClick={ click__hide } href="/file/pdf/resume.pdf">Resume</a>
                {/* <HashLink onClick={ click__hide }  to="#contact" smooth>Contact</HashLink> */}
            </nav>
        </header>
    )
}


export default Header
