const default_state = { menu__toggled: false }


export default (state = default_state, action) => {
    switch (action.type) {
        case `TOGGLER::TOGGLE__MENU`:
            return { menu__toggled: !state.menu__toggled }
        case `TOGGLER::HIDE`:
            return { menu__toggled: false }
        default:
            return state
    }
}
