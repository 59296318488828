import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import toggler from 'store/reducer/toggler'


const config = {
    key: `root`,
    storage,
    whitelist: [ ],
}


const reducer = combineReducers({
    toggler
})


export default persistReducer(config, reducer)
