const Footer = _ => {
    return (
        <footer id="footer">
            {/*<nav>
                <div>
                    <a className="fab fa-linkedin" href="https://linkedin.com/in/"/>
                </div>
                <div>
                    <a className="fab fa-git" href="https://gitlab.com/"/>
                </div>
            </nav>*/}
            <p><span>&copy; WILHELM</span><span>HABSBURG <time dateTime="2022">2022</time></span></p>
        </footer>
    )
}


export default Footer
