import Title from './section/Title'
import About from './section/About'
import Portfolio from './section/Portfolio'
import Contact from './section/Contact'


const Home = _ => {
    return (
        <main id="main">
            <Title/>
            <About/>
            <Portfolio/>
            {/* <Contact/> */}
        </main>
    )
}


export default Home
