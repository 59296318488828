const Title = _ => {
    return (
        <header id="title">
            <h1>Friedrich Wilhelm Albert von Habsburg</h1>
            <p>Mathematician | Full Stack Developer</p>
        </header>
    )
}


export default Title
